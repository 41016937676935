import React from 'react'
import {SOCIALS} from '../data/sociallinks';

const SocialLinks = () => {
  return (
    <div className="hidden lg:flex flex-col top-[35%] left-0 fixed">
      <ul>
        {SOCIALS.map(socmed => (
            <li key={socmed.id} className="flex justify-between items-center w-40 h-14 px-4 bg-gradient-to-r
            from-red-500 to-rose-800 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300">
              <a href={socmed.linkUrl} target="_blank" rel='noreferrer'
              className="flex justify-between items-center w-full text-white" download={socmed.download}>
                <>{socmed.name} {socmed.linkIco}
                </>
              </a>
            </li>
        ))}
      </ul>
    </div>
  )
}

export default SocialLinks