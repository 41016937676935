import React from 'react'
import FrontProfile from "../assets/images/prof-pics/front-profile.png"
import {RiArrowRightSLine} from 'react-icons/ri';
import { BLURB } from '../data/experience';
import {Link as ScrollLink} from 'react-scroll'; 
import HomeContact from './home-modules/HomeContact';
import HomeDesigns from './home-modules/HomeDesigns';
import HomeProjects from './home-modules/HomeProjects';
import HomeAbout from './home-modules/HomeAbout';
import Squiggle from "../assets/images/squiggle.svg";
import ParallaxPanel from './panels/ParallaxPanel';
import PxImage1 from '../assets/images/parallax/parallax-1.png'



const Home = () => {

  return (
    <>
        <div name="home" className="h-full w-full text-white">
       
        <div className="bg-[#18191A]">
            <div className="max-w-screen-lg min-h-screen mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row text-white " data-aos="fade-up" >
                <div className="w-1/2 flex flex-col justify-center h-full mt-[20%] sm:mt-[5%]">

                        <h2 className="text-3xl sm:text-5xl font-bold" >
                            <span>Hello, I'm</span> 
                            <span className="p-3"style={{backgroundImage: `url(${Squiggle})`, 
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat'}}>Gab</span>
                        </h2>

                    

                    <p className="text-gray-200 my-4 py-4 leading-relaxed text-sm sm:text-base">
                        {BLURB}
                    </p>

                    <div>
                        <button className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r
                            from-red-500 to-rose-800 cursor-pointer">
                            <ScrollLink to="contact" className="flex" smooth duration={750}>
                                Contact Me <span className="group-hover:rotate-90 duration-300 ml-1"><RiArrowRightSLine size={25}/></span>
                            </ScrollLink>
                        </button>
                    </div>
                </div>

                <div className="w-2/3 sm:w-1/2 p-6 sm:p-[5%]">
                    <img src={FrontProfile} alt="Gabriel Reyes, UI/UX Designer, Industrial Engineer, Software Developer" className="rounded-3xl mx-auto object-fit"/>
                </div>
            </div>

        </div>
        

        <ParallaxPanel imageToPx={PxImage1}/>
        
        <HomeAbout/>

        <HomeProjects/>

        <HomeDesigns/>

        <HomeContact/>
            
      </div>
    </>
    
  )
}

export default Home