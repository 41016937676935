export const RANDOMWORDS = [
    "an Industrial Engineer", 
    "a Software Engineer",
    "a UI/UX designer", 
    "artistic",
    "analytical",  
    "creative",
    "a leader",
    "spontaneous",
    "process-minded",
    "self-motivated",
    "goal-driven",
    "a problem solver",
    "ready for challenges",
    "ready to help out",
    "Gab Reyes"
]

export const SERV_WRITEUP = "I've worn plenty of hats over the course of my career in tech, and that lets me provide different perspectives, skillsets and experiences to help your company out. Be it in design or development, my skills can contribute to your company's growth."

export const HI_STRING = "Hello, I'm Gab."

export const EMAIL_STR = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

export const CONTACT_ME_STR = "Looking to connect? Don't hesitate to get in touch with me by clicking the button below."
export const CONTACT_ME_W_RES = "Want to know more? You can download my resume below, or get in touch by clicking the Contact Me button."