import BEAUTYBYORANGE from '../assets/images/designs-images/beauty-by-orange.png';
import BOOKMARQ from '../assets/images/designs-images/bookmarq.png';
import CHOCABOBA from '../assets/images/designs-images/choca-boba.png';
import COFFEECAT from '../assets/images/designs-images/coffeecat-cafe.png';
import DIVEQUEEN from '../assets/images/designs-images/divequeen.png';
import GARAMONDE from '../assets/images/designs-images/garamonde.png';
import GOOGLECERT from '../assets/images/designs-images/google-cert-image.png';
import GFFDESKTOP from '../assets/images/designs-images/gff-desktop.png';
import GFFMOBILE from '../assets/images/designs-images/gff-mobile.png';
import GFFTABLET from '../assets/images/designs-images/gff-tablet.png';
import QBIX from '../assets/images/designs-images/qbix.png';

export const DESIGNS = [
    {
        id: 1,
        name: "Bookmarq Splash Design",
        designImg: BOOKMARQ
    },
    {
        id: 2,
        name: "CoffeeCat Cafe Splash Design",
        designImg:COFFEECAT,

    },
    {
        id: 3,
        name: "Qbix Solutions Splash Design",
        designImg: QBIX
        
    },
    {
        id: 4,
        name: "Choca Boba Splash Design",
        designImg: CHOCABOBA
    },
    {
        id: 5,
        name: "Beauty By Orange Splash Design",
        designImg: BEAUTYBYORANGE,
    },
    {
        id: 6,
        name: "DiveQueen Splash Design",
        designImg: DIVEQUEEN,
    },
    {
        id: 7,
        name: "Garamonde Splash Design",
        designImg: GARAMONDE,

    },
    {
        id: 8,
        name: "Orion Gaming App",
        designImg: GOOGLECERT
    },
    {
        id: 9,
        name: "GetFreshFood Desktop Screen",
        designImg: GFFDESKTOP
    },
    {
        id: 10,
        name: "GetFreshFood Mobile Screens",
        designImg: GFFMOBILE
    },
    {
        id: 11,
        name: "GetFreshFood Tablet Screens",
        designImg: GFFTABLET
    },

]

export const DES_WRITEUP = "I've worked on numerous web and mobile designs using Figma. My unique experience and skills allows me to approach design with a pragmatic perspective, considering both the developer's needs and the user's experience. Check out some of my designs below."

export const DES_WRITEUP_LONG = "Whether it's designing a website or a mobile app, I understand the importance of striking the right balance between form and function. My background in software development has given me an edge in understanding the practicalities of implementing a design, and I always keep this perspective in mind while working on a project. My design philosophy centers around the user, and I strive to create interfaces that are intuitive, user-friendly, and visually appealing."