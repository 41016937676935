import React, {useEffect} from 'react'
import { DESIGNS, DES_WRITEUP } from '../../data/designs'
import DesignCarousel from '../panels/DesignCarousel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Squiggle from "../../assets/images/squiggle.svg";
import {Link} from "react-router-dom";
import {RiArrowRightSLine} from 'react-icons/ri';

const HomeDesigns = () => {

  useEffect(()=>{
    AOS.init({duration: 2500});
 },[])

 const subdesigns = DESIGNS.slice(0, 5)

  return (
    <div name="designs" className="h-full w-full bg-gradient-to-b to-[#242526] from-[#18191A] text-white py-[4%] border-hidden">
        <div data-aos="fade-up">
          <div className="w-2/3 sm:w-full max-w-screen-lg p-4 mx-auto flex flex-col justify-center h-full" >
              <div className="pb-[5%] mt-[20%] sm:mt-[10%]">
                  <h2 className="text-3xl sm:text-5xl font-bold text-white inline" style={{backgroundImage: `url(${Squiggle})`, 
                              backgroundPosition: 'left',
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat'}}>Designs</h2>
              </div>
              
              <p className="py-4 text-gray-200 mb-4 leading-relaxed text-sm sm:text-base mx-auto">{DES_WRITEUP}</p>
            
          </div>

          <div className="w-5/6 sm:w-2/3 mx-auto p-4 sm:p-10 bg-gray-200 shadow-lg rounded-xl bg-clip-padding bg-opacity-20 border border-gray-700">
                <DesignCarousel autoSlide={true} autoSlideInterval={3000}>
                    {subdesigns.map((design) => (
                        <>
                          <img src={design.designImg} alt={design.description} />
                        </>    
                    ))}
                </DesignCarousel>
          </div>
          
          <div className="w-full flex justify-center mt-6">
                      <button className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r
                          from-red-500 to-rose-800 cursor-pointer">
                          <Link to="/designs" className="flex" smooth duration={750}>
                              See More <span className="group-hover:rotate-90 duration-300 ml-1"><RiArrowRightSLine size={25}/></span>
                          </Link>
                      </button>
                  </div>


        </div>
        
    </div>
  )
}

export default HomeDesigns