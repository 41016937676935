import React, {useState, useEffect} from 'react'
import { DESIGNS, DES_WRITEUP_LONG } from '../data/designs';
import {Link} from "react-router-dom";
import {RiArrowRightSLine} from 'react-icons/ri';
import AOS from 'aos';
import 'aos/dist/aos.css';
import DesignPanel from './panels/DesignPanel';
import Squiggle from "../assets/images/squiggle.svg";
import { CONTACT_ME_STR } from '../data/randomwords';


const Designs = () => {
    useEffect(()=>{
        AOS.init({duration: 2000});
     },[])

     const [openPanel, setOpenPanel] = useState(false);
     const [currDesId, setCurrDesId] = useState(1);
     const closeDesign = () => {
         setOpenPanel(false);
         document.body.style.overflow = 'unset'
     }
 
     const openDesign = (id) => {
         document.body.style.overflow = 'hidden'
         setCurrDesId(id);
         setOpenPanel(true);
     }

  return (
    <div name="designs" className="h-full w-full bg-gradient-to-b  to-[#242526] from-[#18191A] text-white pb-[10%]">
        
        <div className="w-2/3 sm:w-full max-w-screen-lg p-4 mx-auto flex flex-col justify-center h-full">
            <div className="pb-[5%] mt-[10%] sm:mt-[2%]">
                <h2 className="text-3xl sm:text-5xl font-bold text-white inline" style={{backgroundImage: `url(${Squiggle})`, 
                            backgroundPosition: 'left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'}}>Designs</h2>
            </div>
            
            <p className="py-4 text-gray-200 mb-4 leading-relaxed text-sm sm:text-base mx-auto">{DES_WRITEUP_LONG}</p>
           

            <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8 text-center justify-center items-center pb-[10%] sm:pb-[2%] mb-[6%]">
              {DESIGNS.map((design) => (
                     <div key={design.id} className="group shadow-lg shadow-gray-600 rounded-lg" data-aos="fade-up">
                        <img src={design.designImg}  alt={design.description} className="mx-auto my-auto m-6 object-contain rounded-md duration-200 group-hover:scale-105 cursor-pointer"
                        onClick={() => openDesign(design.id)} />
                    </div>
              ))}
          </div>

          <div className="w-full flex flex-col justify-around text-center my-6">
                    <h1 className="font-bold text-lg py-4 leading-relaxed sm:text-3xl w-2/3 sm:w-full mx-auto">
                        { CONTACT_ME_STR }
                    </h1>
                    <button className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r
                        from-red-500 to-rose-800 cursor-pointer mx-auto">
                        <Link to="/contact" className="flex" smooth duration={750}>
                            Contact Me <span className="group-hover:rotate-90 duration-300 ml-1"><RiArrowRightSLine size={25}/></span>
                        </Link>
                    </button>
            </div>
        </div>

          

            <div>
                <DesignPanel open={openPanel} close={closeDesign} desId={currDesId}/>
            </div>
    </div>
  )
}

export default Designs