import React from 'react'
import { DESIGNS } from '../../data/designs'
import {RiCloseFill} from 'react-icons/ri';


const DesignPanel = ({open, close, desId}) => {
    const design = DESIGNS[desId - 1];

  return (
        <div className={`fixed z-50 w-full h-full bottom-0 inset-x-0 transition-all ease-in-out duration-500 ${open? "pointer-events-auto" : "pointer-events-none"}`}>
        <div className={`h-full bg-gray-700 bg-opacity-75 transition-opacity ease-in-out duration-200 sm:duration-300 ${open ? "opacity-100" : "opacity-0"}`} onClick={close}/>

            <div className={`flex justify-between p-6 items-center mx-auto sm:max-w-7xl absolute inset-0 ${open ? "opacity-100" : "opacity-0"} `}>
                <div className="relative">
                    <img src={design.designImg}  alt={design.description} className="mx-auto my-auto m-6 object-contain rounded-2xl"/>
                    <button className="absolute rounded-full shadow p-2 bg-white/50 top-4 right-4 hover:scale-125 duration-300"><RiCloseFill color="#18191A" onClick={close} size={40}/></button>
                    <div className="absolute hidden md:flex center bg-opacity-60 bg-black p-4 rounded-md pl-6 bottom-4 left-4">
                        <span>{design.name}</span>
                    </div>
                </div>
                
            </div>
            
        </div>
  )
}

export default DesignPanel