import CAPSLogo from '../assets/images/logos/caps-logo.svg';
import LSLogo from '../assets/images/logos/live-sell-logo.svg';
import GFFLogo from '../assets/images/logos/getfresh-logo.svg';
import EMLogo from '../assets/images/logos/emoney-logo.svg';
import OrionLogo from '../assets/images/logos/orion-logo.svg';

export const PROJ_WRITEUP = "I've worked as both a developer and designer on multiple projects, always prioritizing the conversion of user requirements into programmable solutions."

export const PROJ_WRITEUP_LONG ="Throughout my career as a developer and designer, I've had the opportunity to work on a diverse range of projects. In each project, my focus has been on translating user requirements into codable and programmable solutions. I believe that understanding the end user is critical to creating software that truly meets their needs. Whether it's a small feature or a large-scale project, I take the time to understand the user's perspective and create solutions that exceed their expectations."

export const PROJECTS = [
    {
        id: 1,
        title: "Course Administration System",
        imgProj: CAPSLogo,
        description: 
        ["The Course Administration System is a project that allows users to log in and register as a student, lecturer, or admin staff and manage their own information, such as grades, classes, and courses. The system is designed with multiple modules that enable users to view and enroll in courses, assign grades to students, and manage the student, lecturer, and course database.",
        "As a student, you can see the list of courses available and enroll in any class. You can also view your current classes and respective grades. As a lecturer, you can manage student grades and remove students who are not performing well. Admin staff have full access to the database and can add, edit, and delete any information. To prevent multiple logins and unauthorized access, interceptor layers were implemented.",
        "The project was developed using Java Spring Boot, with the frontend developed in Thymeleaf."],
        linkToProj: "https://github.com/gcreyes31/course-admin-system"
    },
    {
        id: 2,
        title: "Live Selling App Prototype",
        imgProj: LSLogo,
        description: ["The Vivamus Live Selling Application is a ive-selling platform that allows users to register and set up their own live selling streaming channel. The application features a mobile (Android) and web (React) frontend, with a Java Spring Boot backend and Python-powered machine learning capability. It is deployed in Microsoft Azure SQL.",
        "Once registered, users can log in to their account and set up products, start and schedule live streams, and process orders from customers that tune in during their streams. The platform offers buyer and seller functionalities, allowing customers to order products from sellers and giving sellers the ability to manage their products and schedule streams. Additionally, a dashboard feature is available for sellers to make use of the machine learning prediction features to conduct their sales more effectively.",
        "As a member of the team building Vivamus, I was responsible for developing the Android frontend, writing APIs in Java Spring Boot for the frontend calls."],
        linkToProj: "https://github.com/gcreyes31/live-selling-android-frontend"
    },
    {
        id: 3,
        title: "Grocery Software System Design",
        imgProj: GFFLogo,
        description: ["GetFreshFood grocery store was in need of a digital transformation to automate their restocking and price assignment processes. To meet their requirements, a design process was initiated that involved analyzing the existing system and interviewing different users like cashiers, store and inventory people, and managers. The objective was to create an automated system that could accommodate the tasks and needs of different users without disrupting the current system.",
        "Interviews with the staff of GetFreshFood were first conducted, to pinpoint the company's needs. The next step was to create personas and user stories based on the interviews, which helped the team focus on the problems that needed to be addressed and the capabilities the software should have. The team then built conceptual models of the application using different diagrams like Use Case, Statechart, and Class. After this, screens designed for web/desktop, tablet, and mobile versions were created to ensure not just compatibility across different platforms, but also to meet specific user requirements.",
        "In the Designs section, you can see some of the desktop and mobile designs created for the GetFreshFood app using Figma."],
        linkToProj: ""
    },
    {
        id: 4,
        title: "Orion Gaming E-Commerce App Design",
        imgProj: OrionLogo,
        description: ["The Orion Gaming E-Commerce App Design was created as part of the Google UI/UX Certificate Course with a focus on mobile gaming e-commerce. To ensure that the app met the needs of its target audience, research was conducted to gather user requirements. The demographic for the app was teenagers who enjoy playing games and young professionals who play games during their free time. Through interviews with potential users, personas were created to represent the common patterns and themes identified during the research phase.",
        "Once the personas were created, user stories were made to categorize the points that the app should address. Multiple ideas were brainstormed (via storyboards, paper prototypes, etc.) to find the best solution for addressing the identified points. I then moved on to create low-fidelity prototypes in Figma, which allowed them to create a simplified, black-and-white version of the app that focused on streamlining the user flow. After multiple iterations of the low-fidelity prototype, a high-fidelity prototype was developed with actual designs.",
        "Overall, the design process for this app involved a thorough research phase, the creation of personas and user stories, multiple rounds of prototyping, and a strong focus on meeting the needs of the target audience. You can see some of the mobile designs for the Orion app made in Figma in the Designs section. The link below shows the clickable low-fidelity and high-fidelity wireframes."],
        linkToProj: "https://www.figma.com/proto/noYUPOehYhwytcgzCLYWOD/UI%2FUX-Portfolio?node-id=22%3A53&scaling=scale-down&page-id=22%3A51&starting-point-node-id=22%3A53&show-proto-sidebar=1"
    },
    {
        id: 5,
        title: "E-Money App Template Prototype",
        imgProj: EMLogo,
        description: ["The E-Money project is an template prototype designed to create white-label contactless payment platforms for mobile devices. Every company has its unique approach to doing contactless payments, and this project was designed to be flexible and customizable according to the company’s branding and processes.",
        "Extensive research was conducted to identify the commonalities in the contactless payment process. The study showed that most contactless payment transactions are connected to the mobile number of the phone, and the use of QR Codes for payment is a more convenient alternative. Users are also allowed to save/register other users for scheduled or repeated payments. These features and more were kept in mind while creating a template prototype that can accommodate different designs and additional features, with minimal changes to the core functionality of a contactless payment app.",
        "You can access the Figma page through the link below to view the process flow of the template prototype."],
        linkToProj: "https://www.figma.com/proto/g5lip2GoBC41IPa46sm38X/E-Money?node-id=108%3A434&scaling=scale-down&page-id=1%3A4&starting-point-node-id=108%3A434&show-proto-sidebar=1"
    }
]