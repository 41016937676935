import React, {useState} from 'react'
import {FaBars, FaTimes} from 'react-icons/fa';
import {NavLink} from "react-router-dom";
import LightLogo from "../assets/images/gr-icon-white.svg";
import { LINKS } from "../data/menuoptions.js";

const Navbar = () => {
    const [nav, setNav] = useState(false);

  return (
    <div className={`top-0 flex justify-between items-center w-full h-20 text-white bg-[#18191A] px-4 sticky z-40 shadow-md shadow-black sm:mx-auto`}>
        <div className="ml-[7%]">
            <NavLink to="/">
            <img src={LightLogo} alt="Home icon" className="h-10" />
            </NavLink>
        </div>

        <ul className="hidden sm:flex">
            {LINKS.map(({id, link, linkTo}) => (
                <li key={id} className="px-4 cursor-pointer capitalize font-medium text-gray-500 hover:scale-110 duration-200 hover:text-white">
                    <NavLink to={linkTo} smooth duration={500} className={({ isActive }) => isActive ? 'text-white font-bold' : undefined}>{link}</NavLink>
                </li>
                
            ))}
        </ul>

        <div onClick={() => setNav(!nav)} className="cursor-pointer pr-4 z-10 text-gray-500 sm:hidden">
            {nav ? <FaTimes size={30}/> : <FaBars size={30}/>}
        </div>
        
        {nav &&  (
            <ul className={`flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b to-[#242526] from-[#18191A] text-gray-500 overflow-auto overscroll-contain`}>
                {LINKS.map(({id, link, linkTo}) => (
                    <li key={id} className="px-4 cursor-pointer capitalize py-6 text-4xl">
                        <NavLink to={linkTo} smooth duration={500} onClick={() => setNav(!nav)} className={({ isActive }) => isActive ? 'text-white font-bold' : undefined}>{link}</NavLink>
                    </li>
                ))}
            </ul>
        )}


    </div>
  )
}

export default Navbar