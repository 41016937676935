import React, {useEffect} from 'react';
import {PROJECTS, PROJ_WRITEUP} from "../../data/projects.js";
import {Link} from "react-router-dom";
import {RiArrowRightSLine} from 'react-icons/ri';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Squiggle from "../../assets/images/squiggle.svg";

const HomeProjects = () => {

    useEffect(()=>{
        AOS.init({duration: 2500});
     },[])

  return (
    <div name="projects" className={`h-full w-full bg-gradient-to-b from-[#242526] to-[#18191A] text-white flex flex-col border-hidden py-2`}>
        
        <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center h-full w-full my-[20%] sm:my-[5%]" data-aos="fade-up">
            <div className="py-[4%] w-2/3 sm:w-full mx-auto">
                <h2 className="text-3xl sm:text-5xl font-bold text-white inline" style={{backgroundImage: `url(${Squiggle})`, 
                            backgroundPosition: 'left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'}}>Projects</h2>
                
            </div>

            <p className="py-4 text-gray-200 mb-4 leading-relaxed text-sm sm:text-base w-2/3 sm:w-full mx-auto">
                {PROJ_WRITEUP}
            </p>

            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0 text-center items-center">
                {PROJECTS.map((project) => (
                     <div key={project.id} className="group shadow-lg shadow-gray-600 rounded-lg duration-200 hover:scale-110">
                        <img src={project.imgProj}  alt={project.title} className="h-20 p-4 mx-auto my-auto m-6 object-contain" />
                    </div>
                ))}
            </div>

            <div className="w-full flex justify-center mt-6">
                    <button className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r
                        from-red-500 to-rose-800 cursor-pointer">
                        <Link to="/projects" className="flex" smooth duration={750}>
                            See More <span className="group-hover:rotate-90 duration-300 ml-1"><RiArrowRightSLine size={25}/></span>
                        </Link>
                    </button>
                </div>
        </div>
        
    </div>
  )
}

export default HomeProjects