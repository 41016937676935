import React, {useState, useEffect} from 'react'
import {RiArrowRightSLine, RiArrowLeftSLine} from 'react-icons/ri';
import { DESIGNS } from '../../data/designs';

export default function DesignCarousel ({
        children: designs,
        autoSlide=false,
        autoSlideInterval=3000}) {
    const [currDes, setCurrDes] = useState(0);

    const prev = () => {
        setCurrDes((currDes) => currDes === 0 ? designs.length - 1 : currDes - 1);
    }

    const next = () => {
        setCurrDes((currDes) => currDes === designs.length - 1 ? 0 : currDes + 1);
    }

    useEffect(() => {
        if (!autoSlide) return;
        const slideInterval = setInterval(next, autoSlideInterval)
        return () => clearInterval(slideInterval)
    })


    return (
        <div className="relative overflow-hidden">
            <div className="flex transition-transform ease-out duration-500" style={{transform: `translateX(-${currDes * 100}%)`}}>
                {designs}
            </div>
            <div className="absolute inset-0 flex items-center justify-between p-4">
                <button onClick={prev} className="p-1 rounded-full shadow bg-white/80 opacity-50 text-gray-800 hover:bg-white hover:opacity-80 duration-200">
                    <RiArrowLeftSLine size={40}/>
                </button>
                <button onClick={next} className="p-1 rounded-full shadow bg-white/80 opacity-50 text-gray-800 hover:bg-white hover:opacity-80 duration-200">
                    <RiArrowRightSLine size={40}/>
                </button>
            </div>


            <div className="absolute bottom-4 right-0 left-0">
                <div className="flex items-center justify-center gap-2">
                    {designs.map((_, i) => (
                        <div className={`transition-all w-2 h-2 bg-gray-800 rounded-full ${currDes===i ? "p-2" : "bg-opacity-50"}`}></div>
                    ))}
                </div>
            </div>

            <div className="hidden md:flex absolute bottom-2 -left-2 center bg-opacity-60 bg-black p-4 rounded-md pl-6 ${}">
                <span>{DESIGNS[currDes].name}</span>
            </div>
        </div>
    )
}