import React, { useState, useEffect} from 'react'
import { EXPERIENCE, ABOUT_ME } from '../../data/experience';
import { RANDOMWORDS } from '../../data/randomwords';
import {Link} from "react-router-dom";
import {RiArrowRightSLine} from 'react-icons/ri';
import AOS from 'aos';
import 'aos/dist/aos.css';
import TextTransition, { presets } from "react-text-transition";


//const DescriptionPanel = React.lazy(() => import('./DescriptionPanel'));

const HomeAbout = () => {

    const [nextWord, setNextWord] = useState("Gab Reyes");
    const [index, setIndex] = useState(1)


    useEffect(() => {
        const interval = setInterval(() => {
            index >= RANDOMWORDS.length ? setIndex(1) : setIndex((num) => num+1);
            
            setNextWord(RANDOMWORDS[index - 1]);
        }, 2000);
        return () => clearInterval(interval);
    }, [index]);

        
    useEffect(()=>{
        AOS.init({duration: 2500});
     },[])


  return (
    <div name="about" className="h-full w-full bg-gradient-to-b to-[#242526] from-[#18191A] border-hidden py-2">
        <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row text-white">
            
            <div className="flex flex-col justify-center my-[10%] sm:my-[5%]"  data-aos="fade-up">

                <div className="inline sm:flex text-4xl sm:text-5xl font-bold text-white py-[6%] sm:py-[4%] mb-[6%] sm:mb-0 mt-[10%] w-2/3 sm:w-full mx-auto">
                    <span className>I'm&nbsp;</span>
                    <TextTransition springConfig={presets.gentle}>
                        <span className="mx-auto bg-gradient-to-r from-red-500 to-rose-800 text-transparent bg-clip-text py-4">{nextWord}</span>.
                    </TextTransition>
                </div>
                
                <p className="text-gray-200 py-4 leading-relaxed text-sm sm:text-base w-2/3 sm:w-full mx-auto">
                    {ABOUT_ME}
                </p>

                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-center py-8 pb-4 px-12 sm:px-0">
                    {EXPERIENCE.map((exp) => (
                        <div key={exp.id} className={`shadow-lg hover:scale-110 duration-200 py-4 px-4 rounded-lg outline outline-3 ${exp.style}`}>
                            <img src={exp.imgExp} alt="exp-img" className="h-20 mx-auto my-auto mb-6 object-contain"/>
                            <h2 className="mt-3 text-xl font-bold">{exp.name}</h2>
                        </div>
                    ))}
                </div>
                <div className="w-full flex justify-center mt-6">
                    <button className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r
                        from-red-500 to-rose-800 cursor-pointer">
                        <Link to="/about" className="flex" smooth duration={750}>
                            See More <span className="group-hover:rotate-90 duration-300 ml-1"><RiArrowRightSLine size={25}/></span>
                        </Link>
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeAbout