import UIUXlogo from "../assets/images/logos/ui-ux-logo.svg";
import SElogo from "../assets/images/logos/soft-engg-logo.svg";
import IndExplogo from "../assets/images/logos/ind-exp-logo.svg";
import GRIcon from "../assets/images/gr-icon-white.svg"

//icon imports
import HTMLIcon from "../assets/images/logos/about-icons/html.png"
import CSSIcon from "../assets/images/logos/about-icons/css-3.png"
import ReactIcon from "../assets/images/logos/about-icons/reacticon.png"
import AndroidIcon from "../assets/images/logos/about-icons/android-studio.png"
import FigmaIcon from "../assets/images/logos/about-icons/figma-128.png"
import TailwindIcon from "../assets/images/logos/about-icons/tailwindcss-128.png"
import GIMPIcon from "../assets/images/logos/about-icons/gimp-128.png"
import XDIcon from "../assets/images/logos/about-icons/adobexd-128.png"
import JSIcon from "../assets/images/logos/about-icons/javascript-128.png"
import GoIcon from "../assets/images/logos/about-icons/golang-128.png"
import JavaIcon from "../assets/images/logos/about-icons/java-128.png"
import CSharpIcon from "../assets/images/logos/about-icons/c-sharp-128.png"
import PythonIcon from "../assets/images/logos/about-icons/python-128.png"
import SQLIcon from "../assets/images/logos/about-icons/sql-128.png"
import GitIcon from "../assets/images/logos/about-icons/git-128.png"
import NormaIcon from "../assets/images/logos/about-icons/normalization.png"
import ProcessIcon from "../assets/images/logos/about-icons/process.png"
import DTIcon from "../assets/images/logos/about-icons/design-thinking.png"
import UMLIcon from "../assets/images/logos/about-icons/uml.png"


export const SOFTWARE_DES = {
    title: "User Experience & Software Design",
    imgExp: UIUXlogo,
    style: 'shadow-blue-600 outline-blue-600/20',
    underColor: 'decoration-blue-600',

    sections:  [
        {
            id: 1,
            name: "Figma",
            icon: FigmaIcon,
            style: 'shadow-[#ee838c] outline-[#ee838c]/20 hover:outline-4',
        },
        {
            id: 2,
            name: "Adobe XD",
            icon: XDIcon,
            style: 'shadow-[#ff41c8] outline-[#ff41c8]/20 hover:outline-4',
        },
        {
            id: 3,
            name: "GIMP",
            icon: GIMPIcon,
            style: 'shadow-[#878787] outline-[#878787]/20 hover:outline-4',
        },
        {
            id: 4,
            name: "Design Thinking (Personas, User Journeys, Sprints, etc.)",
            icon: DTIcon,
            style: 'shadow-[#4B7AF0] outline-[#4B7AF0]/20 hover:outline-4',
        },
        {
            id: 5,
            name: "UML (Unified Modeling Language) Diagramming",
            icon: UMLIcon,
            style: 'shadow-[#2ee66b] outline-[#2ee66b]/20 hover:outline-4',
        },
        {
            id: 6,
            name: "Database Normalization",
            icon: NormaIcon,
            style: 'shadow-[#5ad7ff] outline-[#5ad7ff]/20 hover:outline-4',
        },
        {
            id: 7,
            name: "User, Data and Process Flows",
            icon: ProcessIcon,
            style: 'shadow-[#00ab94] outline-[#00ab94]/20 hover:outline-4',
        }
    ]
}

export const SOFTWARE_ENGG = {
    title: "Software Engineering",
    imgExp: SElogo,
    style: 'shadow-green-400 outline-green-400/20',
    underColor: 'decoration-green-400',

    sections: [
        {
            id: 1,
            name: "React.JS",
            icon: ReactIcon,
            style: 'shadow-[#00d8ff] outline-[#00d8ff]/20 hover:outline-4',
        },
        {
            id: 2,
            name: "Java (Spring Boot, Apache)",
            icon: JavaIcon,
            style: 'shadow-[#1565C0] outline-[#1565C0]/20 hover:outline-4',
        },
        {
            id: 3,
            name: "Javascript",
            icon: JSIcon,
            style: 'shadow-[#FFD600] outline-[#FFD600]/20 hover:outline-4',
        },
        {
            id: 4,
            name: "HTML",
            icon: HTMLIcon,
            style: 'shadow-[#ff7816] outline-[#ff7816]/20 hover:outline-4',
        },
        {
            id: 5,
            name: "CSS",
            icon: CSSIcon,
            style: 'shadow-[#004ce8] outline-[#004ce8]/20 hover:outline-4',
        },
        {
            id: 6,
            name: "Tailwind",
            icon: TailwindIcon,
            style: 'shadow-[#00acc1] outline-[#00acc1]/20 hover:outline-4',
        },
        {
            id: 7,
            name: "Android",
            icon: AndroidIcon,
            style: 'shadow-[#4984eb] outline-[#4984eb]/20 hover:outline-4',
        },
        {
            id: 8,
            name: "C# (.NET)",
            icon: CSharpIcon,
            style: 'shadow-[#a077db] outline-[#a077db]/20 hover:outline-4',
        },
        {
            id: 9,
            name: "Golang",
            icon: GoIcon,
            style: 'shadow-[#4DD0E1] outline-[#4DD0E1]/20 hover:outline-4',
        },
        {
            id: 10,
            name: "Python (Tensorflow, Keras for Machine Learning)",
            icon: PythonIcon,
            style: 'shadow-[#FFC107] outline-[#FFC107]/20 hover:outline-4',
        },
        {
            id: 11,
            name: "SQL",
            icon: SQLIcon,
            style: 'shadow-[#2687cf] outline-[#2687cf]/20 hover:outline-4',
        },
        {
            id: 12,
            name: "Git/GitHub",
            icon: GitIcon,
            style: 'shadow-[#F4511E] outline-[#F4511E]/20 hover:outline-4',
        }
    ]
}

export const PROF_EXP = {
    title: "Professional Experience",
    imgExp: IndExplogo,
    style: 'shadow-rose-500 outline-rose-500/20',
    underColor: 'decoration-rose-500',

    sections: [
        {
            id: 1,
            name: "Software Solutions Consultant",
            company: "AltPayNet Corp.",
            timeperiod: "Apr 2023 - present",
            description:"UI/UX, Software Solutions Design & Planning, Software Consulting",
            location: "Singapore",
            style: 'outline-rose-500/50 hover:outline-rose-500 hover:outline-4',
        },
        {
            id: 1,
            name: "Software Engineering Intern",
            company: "KAPPS Consulting",
            timeperiod: "Sep 2022 - Jan 2023",
            description:"Software Development (Java, Javascript, SQL) for Supply Chain Financing / Factoring Software Solutions",
            location: "Singapore",
            style: 'outline-rose-500/50 hover:outline-rose-500 hover:outline-4',
        },
        {
            id: 2,
            name: "Process Design Engineer",
            company:"AltPayNet Corp.",
            timeperiod: "Feb 2020 - Feb 2022",
            description:"UI/UX, Software Design & Process Improvement for Online Payment Gateway Solutions",
            location: "Philippines",
            style: 'outline-rose-500/50 hover:outline-rose-500 hover:outline-4',
        },
        {
            id: 3,
            name: "Systems Engineer",
            company:"Webcast Technologies Inc.",
            timeperiod: "May 2019 - Jan 2020",
            description: "UI/UX, Software Design for Location-Based Tracking Software Solutions",
            location: "Philippines",
            style: 'outline-rose-500/50 hover:outline-rose-500 hover:outline-4',
        },
        {
            id: 2,
            name: "Application Developer Associate",
            company:"Accenture Philippines",
            timeperiod: "Aug 2016 - Jul 2017",
            description: "Software Development (C#, VB.Net) for Offshore Client Solutions",
            location: "Philippines",
            style: 'outline-rose-500/50 hover:outline-rose-500 hover:outline-4',
        }
    ]
}

export const ABT_ME = {
    title: "Other Stuff About Me",
    imgExp: GRIcon,
    style: 'shadow-white outline-gray-600/20',
    underColor: 'decoration-gray-600',

    sections: [
        {
            name: "Education",
            itemsUnder: [
                {
                    id: 1,
                    name: "National University of Singapore - Institute of Systems Science (NUS-ISS)",
                    course: "Graduate Diploma in Systems Analysis",
                    timeline: "Feb 2022 - Feb 2023",
                    style: 'shadow-gray-400 outline-gray-600/20',
                },
                {
                    id: 2,
                    name: "University of the Philippines - Diliman (UP Diliman)",
                    course: "Bachelor of Science in Industrial Engineering",
                    timeline: "Jun 2011 - Jun 2016",
                    style: 'shadow-gray-400 outline-gray-600/20',
                },
                {
                    id: 3,
                    name: "Ateneo de Manila University High School (AHS)",
                    course: "Honors Class",
                    timeline: "Jun 2007 - Mar 2011",
                    style: 'shadow-gray-400 outline-gray-600/20',
                }
            ]
        },
        {
            name: "Certifications",
            itemsUnder: [
                {
                    id: 1,
                    course: "Industrial Engineering Certification (CIE #01241-2017)",
                    timeline: "Issued on 2017 by the Industrial Engineering Certification Board (Philippines)",
                    style: 'shadow-gray-400 outline-gray-600/20',
                },
                {
                    id: 2,
                    course: "Google UX Design Certification (Coursera)",
                    timeline: "Covers 5 topics of Google UX Design: (1) Foundation of User Experience Design; (2) Start The UX Design Process: Empathize, Define, Ideate; (3) Conduct UX Research and Test Early Concepts; (4) Build Wireframes and Low-Fidelity Prototypes; (5) Create High Fidelity Designs and Prototypes in Figma",
                    style: 'shadow-gray-400 outline-gray-600/20',
                },
                {
                    id: 3,

                    course: "Programming with Google Go Specialization (Coursera)",
                    timeline: "Covers basics of Go, from functions, methods, interfaces and concurrency",
                    style: 'shadow-gray-400 outline-gray-600/20',
                }
            ]
        },
        {
            name: "Interests",
            itemsUnder: [
                {
                    id: 1,
                    course: "Supply Chain and Logistics",
                    timeline: "Optimizing processes in manufacturing / transit using software solutions",
                    style: 'shadow-gray-400 outline-gray-600/20',
                },
                {
                    id: 2,
                    course: "UI/UX",
                    timeline: "Innovations in reactive/responsive design, new design paradigms and systems",
                    style: 'shadow-gray-400 outline-gray-600/20',
                },
                {
                    id: 3,
                    course: "Fintech",
                    timeline: "Creating new ways for bankless sectors to perform digital payments (financial inclusion)",
                    style: 'shadow-gray-400 outline-gray-600/20',
                },
                {
                    id: 4,
                    course: "Sports (basketball / Formula 1 / bowling / e-sports)",
                    timeline: "Introducing potential competitive growth via software engineering",
                    style: 'shadow-gray-400 outline-gray-600/20',
                }
            ]
        },
    ]
}