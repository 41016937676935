import React from 'react'
import ERROR_IMG from '../assets/images/error-img.svg';

const NotFound = () => {
  return (
    <div name="error" className="min-h-screen h-full w-full bg-gradient-to-b  to-[#242526] from-[#18191A] text-white flex flex-col">
      <div className="items-center justify-center px-4 text-white max-w-screen-lg p-4 mx-auto flex flex-col w-2/3 h-full">
        <img src={ERROR_IMG} alt="Error: Page not found" className="max-h-72 my-10 mx:auto my:auto" />
        <p className="font-bold text-5xl sm:text-7xl">Page not found.</p>
        <p className="text-gray-200 my-4 py-4 leading-relaxed text-sm sm:text-base">Looks like the page you were looking for isn't here. Try using the navigation bar above to go around!</p>
      </div>
    </div>
  )
}

export default NotFound