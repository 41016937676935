import React, {useEffect} from 'react';
import {PROJECTS, PROJ_WRITEUP_LONG} from "../data/projects.js";
import {Link} from "react-router-dom";
import {RiArrowRightSLine} from 'react-icons/ri';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Squiggle from "../assets/images/squiggle.svg";
import { CONTACT_ME_STR } from '../data/randomwords';

const Projects = () => {

    
    useEffect(()=>{
        AOS.init({duration: 2000});
     },[])


  return (
    <div name="projects" className={`h-full w-full bg-gradient-to-b  to-[#242526] from-[#18191A] text-white flex flex-col pb-[10%]`}>
        
        <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center h-full w-full ">
            <div className="pb-[5%] my-[10%] sm:my-[2%] w-2/3 sm:w-full mx-auto">
                <h2 className="text-3xl sm:text-5xl font-bold text-white inline" style={{backgroundImage: `url(${Squiggle})`, 
                            backgroundPosition: 'left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'}}>Projects</h2>
                
            </div>

            <p className="text-gray-200 mb-4 leading-relaxed text-sm sm:text-base w-2/3 sm:w-full mx-auto">
                {PROJ_WRITEUP_LONG}
            </p>

            <div className="w-2/3 sm:w-full mx-auto">
                {PROJECTS.map((proj) => (
                    <div className="my-[5%] mt-[10%]" data-aos={proj.id%2 === 0? "fade-left": "fade-right"}>
                    <div className="flex h-20 justify-center items-center w-full">
                         <img src={proj.imgProj} alt={proj.title} className="max-h-20 max-w-3/4 mx:auto my:auto object-contain"/>
                    </div>
     
                    <div className="relative mt-6 flex-1 mr-4 text-sm sm:text-base">
                         <span className="font-bold text-base sm:text-xl">{proj.title}</span>
                         {proj.description.map((parag) => (
                              <p className="mt-[10%] sm:mt-[2%] text-gray-200 leading-relaxed">
                                 {parag}
                              </p>
                         ))}
                         <br />
                        {proj.linkToProj && <a href={proj.linkToProj} target="_blank" rel='noreferrer' className="underline italic text-blue-500"><p>Project Link</p></a>}
                    </div>
                    </div>
                ))}
            </div>

            <div className="w-full flex flex-col justify-center text-center my-6">
                    <h1 className="font-bold text-lg py-4 leading-relaxed sm:text-3xl w-2/3 sm:w-full mx-auto">
                        { CONTACT_ME_STR }
                    </h1>
                    <button className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r
                        from-red-500 to-rose-800 cursor-pointer mx-auto">
                        <Link to="/contact" className="flex" smooth duration={750}>
                            Contact Me <span className="group-hover:rotate-90 duration-300 ml-1"><RiArrowRightSLine size={25}/></span>
                        </Link>
                    </button>
            </div>
        </div>
        
    </div>
  )
}

export default Projects