export const LINKS = [
    {
        id: 1,
        link: 'home',
        linkTo: "/"
    },
    {
        id: 2,
        link: 'about',
        linkTo: "/about"
    },
    {
        id: 3,
        link: 'projects',
        linkTo: '/projects'
    },
    {
        id: 4,
        link: 'designs',
        linkTo: '/designs'
    },
    {
        id: 5,
        link: 'contact',
        linkTo: '/contact'
    }
];