import React, {useState, useEffect} from 'react';

const OpeningPanel = () => {
    const [showing, setShowing] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowing(false);
        }, 2500);
        return () => clearTimeout(timer);
      }, []);
    
  return (
    <div className={`fixed flex inset-0 z-50 items-center justify-center bg-gradient-to-b to-[#242526] from-[#18191A] via-[#18191A] transition-all ease-in-out duration-1000 ${showing ? 'opacity-100 translate-y-0' : 'opacity-10 -translate-y-full'} h-screen w-full pointer-events-none`}>
        <div className="items-center justify-center mx-auto p-2 overflow-visible" >
            
            <svg className="w-[60%] sm:w-[50%] mx-auto" width="1113" height="1017" viewBox="-200 0 1500 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                class="logo"
                d="M536.963 1C-191.8 238.997 -163.367 724.941 536.963 496.229C1237.29 267.518 1112 668.018 621.842 773.527C929.621 929.791 1112 1016 1112 1016M536.963 1016V294.718C536.963 294.025 536.276 293.542 535.624 293.778L445.386 326.329"
                fill="transparent"
                stroke-linecap="round" 
                stroke-linejoin="round"/>
            </svg>
        </div>
    </div>
  )
}

export default OpeningPanel