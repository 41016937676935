import React, { useState, useEffect} from 'react'
import { ABOUT_ME_LONG } from '../data/experience';
import { SOFTWARE_DES, SOFTWARE_ENGG, PROF_EXP, ABT_ME } from '../data/experience-new';
import { RANDOMWORDS, CONTACT_ME_W_RES } from '../data/randomwords';
import AboutProfile from "../assets/images/prof-pics/about-me-no-coat.png"
import {Link} from "react-router-dom";
import {RiArrowRightSLine} from 'react-icons/ri';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Squiggle from "../assets/images/squiggle.svg";
import TextTransition, { presets } from "react-text-transition";

const About = () => {
    const [nextWord, setNextWord] = useState("Gab Reyes");
    const [index, setIndex] = useState(1)

    useEffect(() => {
        const interval = setInterval(() => {
            index >= RANDOMWORDS.length ? setIndex(1) : setIndex((num) => num+1);
            
            setNextWord(RANDOMWORDS[index - 1]);
        }, 2000);
        return () => clearInterval(interval);
    }, [index]);

    useEffect(()=>{
        AOS.init({duration: 2000});
     },[])

  return (
    <div name="about" className="h-full w-full bg-gradient-to-b to-[#242526] from-[#18191A] pb-[10%]">
        <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row text-white ">
            
            <div className="flex flex-col justify-center overflow-visible"  data-aos="fade-up">
                <div className="w-full p-6 sm:p-[5%] mx-auto" style={{backgroundImage: `url(${Squiggle})`, 
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'}}>
                    <img src={AboutProfile} alt="Gabriel Reyes, UI/UX Designer, Industrial Engineer, Software Developer" className="w-2/3 sm:w-2/5 mt-6 rounded-3xl mx-auto"/>
                </div>

                <div className="inline sm:flex text-4xl sm:text-5xl font-bold text-white py-[6%] sm:py-[4%] mb-[6%] sm:mb-0 mt-[5%] w-2/3 sm:w-full mx-auto">
                    <span className>I'm&nbsp;</span>
                    <TextTransition springConfig={presets.gentle}>
                        <span className="mx-auto bg-gradient-to-r from-red-500 to-rose-800 text-transparent bg-clip-text py-4">{nextWord}</span>.
                    </TextTransition>
                </div>
                
                <p className="text-gray-200 py-4 leading-2 text-sm sm:text-base w-2/3 sm:w-full mx-auto leading-relaxed">
                    {ABOUT_ME_LONG}
                </p>
                {/*
                
                <div className="w-2/3 sm:w-full mx-auto">
                    {EXPERIENCE.map((exp) => (
                        <div className="flex h-full flex-col py-6 mt-6" data-aos="fade-up">
                            <div className="flex h-20 justify-start items-center">
                                <img src={exp.imgExp} alt={exp.title} className="max-h-20 max-w-3/4 mx:auto my:auto object-contain"/>
                            </div>
        
                            <div className="mt-6 flex-1 mr-4">
                                <span className={`text-xl sm:text-2xl font-bold`}>{exp.name}</span>
                                    <ul className="mt-5">
                                        {exp.desc.map((desc) => (
                                            <li key={desc.id} className="my-6">
                                                <span className={`text-base sm:text-lg font-bold underline decoration-4 ${exp.underColor}`}>{desc.title}</span>
                                                {desc.txtafter && 
                                                <div className="text-sm sm:text-base my-2 ">
                                                {desc.txtafter.map((disp)=> (
                                                    <p className="text-gray-200 pt-2 whitespace-pre-line leading-8 sm:mx-6">{disp}</p>
                                                ))}
                                                </div>
                                                }
                                                
                                                {desc.txtafterwheader && 
                                                <div className="text-sm sm:text-base my-2">
                                                {desc.txtafterwheader.map((disp)=> (
                                                    <p className="text-gray-200 pt-2 whitespace-pre-line leading-8 sm:m-6 my-6">
                                                    <span className="font-bold ">{disp.header}</span> : <br/>
                                                    <span className="italic mt-2"> {disp.location}</span>
                                                    </p>
                                                ))}
                                                </div>
                                                }
                                            </li>
                                        ))}
                                    </ul> 
                                </div>                 
                    </div>
                    ))}
                </div>
                
                
                */}

                {/*UI UX*/}
                <div className="w-2/3 sm:w-full mx-auto">
                    <div className="flex h-full flex-col py-6 mt-6" data-aos="fade-up">
                        <div className="flex h-20 justify-start items-center">
                                <img src={SOFTWARE_DES.imgExp} alt={SOFTWARE_DES.title} className="max-h-20 max-w-3/4 mx:auto my:auto object-contain"/>
                        </div>
                        <span className={`text-xl sm:text-3xl font-bold underline decoration-4 my-6 ${SOFTWARE_DES.underColor}`}>{SOFTWARE_DES.title}</span>
                        
                        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-center py-4 pb-4 px-12 sm:px-0">
                        {SOFTWARE_DES.sections.map((item) => (
                            <>
                                <div key={item.id} className={`shadow-lg hover:scale-110 hover:text-white duration-200 py-4 px-4 rounded-lg outline outline-3 text-gray-400 ${item.style}`}>
                                        <img src={item.icon} alt="exp-img" className="h-20 mx-auto my-auto mb-6 object-contain"/>
                                        <h2 className="mt-3 text-md">{item.name}</h2>
                                </div>
                            </>
                        ))}
                         </div>
                    </div>
                </div>

                {/*SOFT ENGG*/}
                <div className="w-2/3 sm:w-full mx-auto">
                    <div className="flex h-full flex-col py-6 my-6" data-aos="fade-up">
                        <div className="flex h-20 justify-start items-center">
                                <img src={SOFTWARE_ENGG.imgExp} alt={SOFTWARE_ENGG.title} className="max-h-20 max-w-3/4 mx:auto my:auto object-contain"/>
                        </div>
                        <span className={`text-xl sm:text-3xl font-bold underline decoration-4 my-6 ${SOFTWARE_ENGG.underColor}`}>{SOFTWARE_ENGG.title}</span>
                        
                        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-center py-4 pb-4 px-12 sm:px-0">
                            {SOFTWARE_ENGG.sections.map((item) => (
                                <>
                                    <div key={item.id} className={`shadow-lg hover:scale-110 hover:text-white duration-200 py-4 px-4 rounded-lg outline outline-3 text-gray-400 ${item.style}`}>
                                        <img src={item.icon} alt="exp-img" className="h-20 mx-auto my-auto mb-6 object-contain"/>
                                        <h2 className="mt-3 text-md">{item.name}</h2>
                                    </div>
                                </>
                            ))}

                        </div>
                        
                    </div>
                </div>

                {/*PROFESSIONAL*/}
                <div className="w-2/3 sm:w-full mx-auto">
                    <div className="flex h-full flex-col py-6 mt-6" data-aos="fade-up">
                        <div className="flex h-20 justify-start items-center">
                                <img src={PROF_EXP.imgExp} alt={PROF_EXP.title} className="max-h-20 max-w-3/4 mx:auto my:auto object-contain"/>
                        </div>
                        <span className={`text-xl sm:text-3xl font-bold underline decoration-4 my-6 ${PROF_EXP.underColor}`}>{PROF_EXP.title}</span>
                        
                        <div className="w-full grid grid-cols-1 gap-8 text-left py-4 pb-4 px-12 sm:px-0">
                            {PROF_EXP.sections.map((part) => (
                                <>
                                <div key={part.id} className={`shadow-lg hover:scale-110 hover:text-gray-200 duration-200 py-4 px-4 rounded-lg outline outline-3 text-gray-400 ${part.style}`}>
                                        <h2 className="text-2xl font-bold mb-2">{part.name}</h2>
                                        <p className="text-md italic">{part.company}, {part.timeperiod}</p>
                                        <p className="text-md my-6">&#x2022; {part.description}</p>
                                </div>
                                </>
                            ))}
                        </div>
                        
                    </div>
                </div>
                

                {/*MORE ABT ME*/}
                <div className="w-2/3 sm:w-full mx-auto">
                    <div className="flex h-full flex-col py-6 mt-6" data-aos="fade-up">
                        <div className="flex h-20 justify-start items-center">
                                <img src={ABT_ME.imgExp} alt={ABT_ME.title} className="max-h-20 max-w-3/4 mx:auto my:auto object-contain"/>
                        </div>
                        <span className={`text-xl sm:text-3xl font-bold underline decoration-4 my-6 ${ABT_ME.underColor}`}>{ABT_ME.title}</span>


                        {ABT_ME.sections.map((part) => (
                            
                            <>
                            <span className={`text-xl sm:text-2xl font-bold`}>{part.name}</span>
                            
                            <div className="w-full grid grid-cols-1 gap-8 text-left mb-8 py-8 pb-4 px-12 sm:px-0">
                                {part.itemsUnder.map((item) => (
                                    <div key={item.id} className={`shadow-lg hover:scale-110 hover:text-gray-200 duration-200 py-4 px-4 rounded-lg outline outline-3 text-gray-400 ${item.style}`}>
                                        <h2 className="text-xl font-bold mb-4">{item.name}</h2>
                                        &#x2022; <span className="text-md underline">{item.course}:</span> &nbsp;
                                        <span className="text-md italic">{item.timeline}</span>
                                    </div>
                                ))}
                            </div>
                            </>
                        ))}
                    </div>
                </div>
                

                <div className="w-2/3 sm:w-full flex flex-col justify-center text-center my-6 mx-auto">
                    <h1 className="font-bold text-lg py-4 leading-relaxed sm:text-3xl w-full mx-auto">
                        {CONTACT_ME_W_RES}
                    </h1>
                    <div className="flex flex-col sm:flex-row items-center justify-between mx-auto">
                        <button className="group text-white w-fit px-6 py-3 my-2 mx-2 flex items-center rounded-md outline outline-rose-800 cursor-pointer">
                        <a href='/Gabriel Chua Reyes - Resume (as of Mar 2023).pdf' target="_blank" rel='noreferrer'
                        className="flex justify-between items-center w-full text-white">
                            Download Resume <span className="group-hover:rotate-90 duration-300 ml-1"><RiArrowRightSLine size={25}/></span>
                        </a>
                        </button>
                        <button className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r
                        from-red-500 to-rose-800 cursor-pointer mx-2">
                        <Link to="/contact" className="flex justify-between items-center w-full" smooth duration={750}>
                            Contact Me <span className="group-hover:rotate-90 duration-300 ml-1"><RiArrowRightSLine size={25}/></span>
                        </Link>
                        </button>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
  )
}

export default About