import UIUXlogo from "../assets/images/logos/ui-ux-logo.svg";
import SElogo from "../assets/images/logos/soft-engg-logo.svg";
import IndExplogo from "../assets/images/logos/ind-exp-logo.svg";
import GRIcon from "../assets/images/gr-icon-white.svg"

export const EXPERIENCE = [
    {
        id: 1,
        name: "User Experience & Software Design",
        imgExp: UIUXlogo,
        desc: [
            {
                id: 11,
                title: "TOOLS USED",
                txtafter: ["Figma Prototyping Tool", "Adobe XD", "GIMP"]
            },
            {
                id: 12,
                title: "METHODS USED",
                txtafter: ["Design Thinking (Personas/User Journeys, Sprints)",  "Process Diagramming (Use Case, Activity, Sequence, Class, Statechart, etc.)", "Database Normalization", "User, Data and Process Flows"]
            },
        ],
        style: 'shadow-blue-600 outline-blue-600/20',
        underColor: 'decoration-blue-600',
        bgTitleColor: 'text-blue-600',
        arrayDisplay: true
    },
    {
        id: 2,
        name: "Software Engineering",
        imgExp: SElogo,
        desc: [
            {
                id: 21,
                title: "FRONT-END",
                txtafter: ["React.JS","Android","HTML/CSS","Tailwind & Bootstrap"]  
            },
            {
                id: 22,
                title: "BACK-END",
                txtafter: ["Java (Spring Boot, Apache Struts)", "C# (.NET Framework)", "Golang", "Python (Tensorflow, Keras for Machine Learning)"]  
            },
            {
                id: 23,
                title: "DATABASE MANAGEMENT & DEVOPS",
                txtafter: ["MySQL", "Git/GitHub"]
            }
        ],
        style: 'shadow-green-400 outline-green-400/20',
        underColor: 'decoration-green-400',
        arrayDisplay: true
    },
    {
        id: 3,
        name: "Professional Experience",
        imgExp: IndExplogo,
        desc: [
            {
                id: 31,
                title: "FINTECH",
                txtafterwheader: [
                    {
                        header: "Software Engineer Intern (KAPPS Consulting, 2022-23)",
                        location: "Software Development (Java, Javascript, SQL) for Supply Chain Financing Software Solutions"
                    },
                    {
                        header: "Process Design Engineer (AltPayNet Corp., 2020-22)",
                        location: "UI/UX, Software Design & Process Improvement for Online Payment Gateway Solutions"
                    },
                ]
            },
            {
                id: 32,
                title: "SUPPLY CHAIN AND LOGISTICS",
                txtafterwheader: [
                    {
                        header: "Systems Engineer (Webcast Technologies Inc., 2019-20)",
                        location: "UI/UX, Software Design for Location-Based Tracking Software Solutions"
                    },
                ]
            },
            {
                id: 33,
                title: "SOFTWARE DEVELOPMENT",
                txtafterwheader: [
                    {
                        header: "Application Development Associate (Accenture Philippines, 2016-17)",
                        location: "Software Development (C#, VB.Net) for Offshore Client Solutions"
                    },
                ]
            },
        ],
        style: 'shadow-rose-500 outline-rose-500/20',
        underColor: 'decoration-rose-500',
        arrayDisplay: true
    },
    {
        id: 4,
        name: "Other Stuff About Me",
        imgExp: GRIcon,
        desc: [
            {
                id: 41,
                title: "EDUCATION",
                txtafterwheader: [
                {
                    header: "National University of Singapore - Institute of Systems Science",
                    location: "Graduate Diploma in Systems Analysis (Feb 2022 - Feb 2023)"
                },
                {
                    header: "University of the Philippines - Diliman",
                    location: "Bachelor of Science in Industrial Engineering (Jun 2011 - Jun 2016)"
                },
                {
                    header: "Ateneo de Manila University",
                    location: "High School, 2nd Honors (Jun 2007 - Mar 2011)"
                }
            ]
            },
            {
                id: 42,
                title: "CERTIFICATIONS",
                txtafterwheader: [
                {
                    header: "Industrial Engineering Certification (CIE #01241-2017)",
                    location: "Issued on 2017 by the Industrial Engineering Certification Board (Philippines)"
                },
                {
                    header: "Google UX Design Certification (Coursera)",
                    location: "Covers 5 topics of Google UX Design: (1) Foundation of User Experience Design; (2) Start The UX Design Process: Empathize, Define, Ideate; (3) Conduct UX Research and Test Early Concepts; (4) Build Wireframes and Low-Fidelity Prototypes; (5) Create High Fidelity Designs and Prototypes in Figma"
                },
                {
                    header: "Programming with Google Go Specialization (Coursera)",
                    location: "Covers basics of Go, from functions, methods, interfaces and concurrency"
                }
            ]
            },
            {
                id: 43,
                title: "INTERESTS",
                txtafterwheader: [
                {
                    header:"Supply Chain and Logistics",
                    location:"Optimizing processes in manufacturing / transit using software solutions"
                },
                {
                    header:"UI/UX",
                    location:"Innovations in reactive/responsive design, new design paradigms and systems"
                },
                {
                    header:"Fintech",
                    location:"Creating new ways for bankless sectors to perform digital payments (financial inclusion)"
                },
                {
                    header:"Sports (basketball / Formula 1 / bowling / e-sports)",
                    location:"Introducing potential competitive growth via software engineering"
                }
            ]
            }
        ],
        style: 'shadow-white outline-gray-600/20',
        underColor: 'decoration-gray-600',
        arrayDisplay: false
    }
]

export const ABOUT_ME = "Since 2016, I've been a professional in the tech industry, seeking to combine my knowledge of math and technology with my appreciation for art and design. Through software design and development, I have found the perfect balance between efficiency and elegance."

export const BLURB = "An industrial engineer, software engineer and UI/UX designer from the Philippines. I’ve always been passionate about finding the easiest way to do things. I aspire to make efficient, effective and elegant solutions to problems that trouble the common person. My ideal? A world where everything is accessible with a press of a button."

export const ABOUT_ME_LONG = "As a professional in the tech industry since 2016, I've always sought to combine my knowledge of math and technology with my appreciation for art and design. That's why software design and development have been the perfect fit for me, allowing me to achieve a wonderful balance between efficiency and elegance. My journey has led me to become proficient in industrial engineering, UI/UX design, and software engineering, giving me a unique perspective on how to optimize business processes while also creating user interfaces and custom software solutions."