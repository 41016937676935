import React, { useRef, useState, useEffect } from 'react'
import { EMAIL_STR } from '../data/randomwords';
import { BiLoaderCircle } from 'react-icons/bi';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Squiggle from "../assets/images/squiggle.svg";
import MetaProp from "../assets/images/prof-pics/metaprop.jpg"

const Contact = () => {
  const formRef = useRef();
  const [submitting, setSubmitting] = useState(false) 
  const [errors, setErrors] = useState({
    nameError: false,
    emailError: false,
    messageError: false
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    const formElement = e.target

    const nameToCheck = formElement.elements['name'].value
    const emailToCheck = formElement.elements['email'].value
    const messageToCheck = formElement.elements['message'].value

    const newErr = {
      nameError: nameToCheck.length < 4,
      emailError: !EMAIL_STR.test(emailToCheck),
      messageError: messageToCheck.length < 10
    }
  
    setErrors(newErr);

    if (!newErr.nameError && !newErr.emailError && !newErr.messageError) {
      formElement.submit()
      setSubmitting(true)
      formElement.elements['name'].value = ""
      formElement.elements['email'].value = ""
      formElement.elements['message'].value = ""
    } 
  }

  useEffect(()=>{
    AOS.init({duration: 2000});
 },[])

  return (
    <div name="contact" className="min-h-screen h-full w-full bg-gradient-to-b  to-[#242526] from-[#18191A] text-white flex flex-col">
      <div className="items-center justify-center px-4 text-white max-w-screen-lg p-4 mx-auto flex flex-col w-full h-full my-[5%] sm:my-[2%]">
        <div className="w-full p-[5%] mb-[10%] sm:mb-[5%] mx-auto" style={{backgroundImage: `url(${Squiggle})`, 
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'}}>
            <img src={MetaProp} alt="Gabriel Reyes, UI/UX Designer, Industrial Engineer, Software Developer" className="w-full sm:w-5/6 mt-6 rounded-xl mx-auto"/>
        </div>

        <div className="w-2/3 sm:w-full mb-[2%]">

          

          <h2 className="text-3xl sm:text-5xl font-bold text-white inline" style={{backgroundImage: `url(${Squiggle})`, 
                            backgroundPosition: 'left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'}}>Contact Me</h2>

          

          <p className="mt-[4%] py-6 text-sm sm:text-base text-gray-200 mx-auto leading-relaxed">Interested in having me join your team as a developer, designer, or consultant/analyst? Don't hesitate to get in touch! <br /> I'll be sure to respond as soon as possible.</p>
        </div>

        <div className="w-2/3 sm:w-full mx-auto items-center flex flex-col md:flex-row justify-between text-sm sm:text-base" data-aos="fade-up">
            <div className="flex flex-col justify-center h-full w-full">
                <div className="text-gray-200 py-4 mb-6 sm:mb-0">
                    <span className="font-bold text-xl text-white mb-[2%]" style={{backgroundImage: `url(${Squiggle})`, 
                            backgroundPosition: 'left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'}}>Phone Numbers:</span>

                    <p className="mb-[2%]">+63 995 450 8026 &#40;Philippines&#41;</p>
                    <p>+65 9612 7308 &#40;Singapore&#41;</p>
                    <br/>
                    <br/>
                    <br/>
                    <span className="font-bold text-xl text-white" style={{backgroundImage: `url(${Squiggle})`, 
                            backgroundPosition: 'left',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'}}>Email:</span>
                    <p>gcreyes.exe@gmail.com</p>
                </div>
            </div>

            <div className="flex flex-col justify-center w-full">
                  <form ref={formRef} onSubmit={handleSubmit} action="https://getform.io/f/456e61d2-2a2c-4353-8ec4-667a5e593c29" method="POST" className="flex flex-col justify-center">
                    <input type="text" name="name" id="name" placeholder='Name' className='p-3 bg-transparent border-2 rounded-md
                    text-white border-gray-600 focus:outline-none mb-4' />
                    <p className={`italic text-red-400 text-sm mb-4 ${errors.nameError ? "visible" : "hidden"}`}>**Name must be longer than 3 characters.</p>

                    <input type="text" name="email" placeholder='Email' className='p-3 bg-transparent border-2 rounded-md
                    text-white border-gray-600 focus:outline-none mb-4' />
                    <p className={`italic text-red-400 text-sm mb-4 ${errors.emailError ? "visible" : "hidden"}`}>**Please enter a valid email.</p>

                    <textarea  name="message" id="message" rows="7" placeholder='Message' className='p-3 bg-transparent border-2 rounded-md
                    text-white border-gray-600 focus:outline-none mb-4'></textarea>
                    <p className={`italic text-red-400 text-sm mb-4 ${errors.messageError ? "visible" : "hidden"}`}>**Message must be longer than 10 characters.</p>

                    <button className="group text-white w-full px-6 py-3 my-2 flex items-center text-center justify-center rounded-md bg-gradient-to-r
                            from-red-500 to-rose-800 cursor-pointer" type="submit">
                                <BiLoaderCircle className={`${submitting ? 'animate-spin' : 'hidden'}`}/>&nbsp;Drop a message!
                    </button>
                  </form>
              </div>
        </div>
            
      </div>
    </div>
  )
}

export default Contact