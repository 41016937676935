import {TiSocialLinkedin} from 'react-icons/ti';
import {FaGithub} from 'react-icons/fa';
import {HiOutlineMail} from 'react-icons/hi';
import {BsFillPersonLinesFill} from 'react-icons/bs';

export const SOCIALS = [
    {
        id: 1,
        name : 'LinkedIn',
        linkIco: <TiSocialLinkedin size={30}/>,
        linkUrl: 'https://www.linkedin.com/in/gabrielreyes31/',
        download: false
    },
    {
        id: 2,
        name : 'GitHub',
        linkIco: <FaGithub size={30}/>,
        linkUrl: 'https://github.com/gcreyes31?tab=repositories',
        download: false
    },
    {
        id: 3,
        name : 'Mail',
        linkIco: <HiOutlineMail size={30}/>,
        linkUrl: 'mailto:gcreyes.exe@gmail.com',
        download: false
    },
    {
        id: 4,
        name : 'Resume',
        linkIco: <BsFillPersonLinesFill size={30}/>,
        linkUrl: '/Gabriel Chua Reyes - Resume (as of Aug 2023).pdf',
        download: false
    }
]